import { FC, SyntheticEvent, useState, useCallback, memo } from "react";
import EditionAccordion from "../Sidebar/EditionAccordion";
import { useNavbarContentContext } from "./NavbarContentContext";
import { handleEditableContentChange, onPropChangeParameters } from "../../utils/Utility";
import EditionTabs from "../Sidebar/EditionTabs";
import { NavbarEditableContent, NavbarContent } from "./NavbarType";

interface Props {}

const NavbarEdition: FC<Props> = (props) => {
    const [accordionExpanded, setAccordionExpanded] = useState<string | false>(false);

    const handleAccordionChange =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setAccordionExpanded(isExpanded ? panel : false);
        };

    const { navbarContent, updateNavbarContent } = useNavbarContentContext();

    const onPropChange = useCallback(
        (arg0: onPropChangeParameters<NavbarContent, NavbarEditableContent>) => {
            handleEditableContentChange({
                ...arg0,
                oldProps: navbarContent,
                update: updateNavbarContent,
            });
        },
        []
    );

    return (
        <>
            {navbarContent.texts.map((navItem, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={navItem.key}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                        sx={{ paddingBottom: "50px" }}
                    >
                        <EditionTabs
                            tabs={navItem}
                            onPropChange={onPropChange}
                            contentName="texts"
                            contentIndex={index}
                        />
                    </EditionAccordion>
                );
            })}
        </>
    );
};

export default memo(NavbarEdition);
