import { Grid, GridProps, BoxProps, Stack, useTheme, useMediaQuery } from "@mui/material";
import React, { FC, useState } from "react";
import SectionBox from "../Utility/SectionBox";
import EditableTypography from "../GenericEditableContent/EditableTypography";
import EditablePlano from "./EditablePlano";
import { usePlanoContentContext } from "./PlanoContentContext";
import FormModal from "../FormModal/FormModalSection";
import { SelectedPlanoInformation } from "./PlanoType";

interface Props extends BoxProps {
    gridDefaultProps: GridProps;
}

const PlanosSection: FC<Props> = (props) => {
    const { planoContent } = usePlanoContentContext();

    const [selectedPlanoIndexInformation, settSelectedPlanoIndexInformation] =
        useState<SelectedPlanoInformation>({
            planIndex: 0,
            licenceIndex: 0,
        });

    const theme = useTheme();
    const upToLaptop = useMediaQuery(theme.breakpoints.up("laptop"));
    const upToPlanoMobile = useMediaQuery(theme.breakpoints.up("planoMobile"));

    return (
        <>
            <FormModal selectedPlanoIndexInformation={selectedPlanoIndexInformation} />
            <SectionBox
                component="section"
                bgcolor="primary.main"
                id="planos_section"
                py={theme.spacing(10)}
                marginTop={theme.spacing(4)}
            >
                <Grid
                    {...props.gridDefaultProps}
                    sx={{
                        justifyContent: { xs: "flex-start", lg: "center" },
                    }}
                    columnSpacing={upToPlanoMobile ? 3 : 0}
                >
                    <Grid item xs={12} paddingTop={theme.spacing(4)}>
                        <Stack spacing={2}>
                            <EditableTypography text={planoContent.texts[0]} />
                            <EditableTypography text={planoContent.texts[1]} />
                        </Stack>
                    </Grid>
                    <Grid
                        container
                        item
                        columnSpacing={upToPlanoMobile ? 3 : 0}
                        sx={{ marginLeft: { desktop: "-48px" } }}
                    >
                        {planoContent.planos.map((plano, index) => {
                            const handleSelectPlan = (licenceIndex: number) => {
                                settSelectedPlanoIndexInformation({
                                    planIndex: index,
                                    licenceIndex,
                                });
                            };

                            return (
                                <Grid
                                    key={index}
                                    item
                                    xs={upToLaptop ? 4 : upToPlanoMobile ? 6 : 12}
                                    sx={{
                                        height: {
                                            mobile: "auto",
                                            planoMobile: "50%",
                                            laptop: "100%",
                                        },
                                        boxSizing: "border-box",
                                        marginTop: { mobile: "25px", laptop: 0 },
                                    }}
                                >
                                    <EditablePlano plano={plano} onSelectPlan={handleSelectPlan} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </SectionBox>
        </>
    );
};

export default PlanosSection;
