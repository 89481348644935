import { FC, useState } from "react";
import AggerTheme from "./utils/AggerTheme";
import { Box, ThemeProvider, GridProps } from "@mui/material";
import { Container } from "@mui/system";
import Navbar from "./components/Navbar/NavbarSection";
import Sidebar from "./components/Sidebar/Sidebar";
import { useWindowSize } from "./utils/Utility";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ContatoSection from "./components/Contato/ContatoSection";
import SobreNosSection from "./components/SobreNos/SobreNosSection";
import DepoimentosSection from "./components/Depoimento/DepoimentosSection";
import NossasSolucoesSection from "./components/NossasSolucoes/NossasSolucoesSection";
import FuncionalidadesSection from "./components/Funcionalidade/FuncionalidadesSection";
import PlanosSection from "./components/Plano/PlanosSection";
import LoginForm from "./components/Login/LoginForm";

import AppContentProvider from "./components/Contexts/AppContentContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface Props {}

const Main: FC<Props> = (props) => {
    const [windowsWidth] = useWindowSize();
    const [mainWidthAvaliable, setMainWidthAvaliable] = useState<number>();
    const [mainMarginLeft, setMainMarginLeft] = useState(0);

    const handleSidebarOpen = (sidebarWidth: number) => {
        setMainWidthAvaliable(windowsWidth - sidebarWidth);
        setMainMarginLeft(sidebarWidth);
    };

    const handleSidebarClose = () => {
        setMainWidthAvaliable(windowsWidth);
        setMainMarginLeft(0);
    };

    const gridContainerDefaultProps: GridProps = {
        container: true,
        rowGap: 6,
        justifyContent: "center",
        maxWidth: "desktop",
        m: "auto",
    };

    return (
        <>
            <Sidebar
                onOpenCallback={handleSidebarOpen}
                onCloseCallback={handleSidebarClose}
            ></Sidebar>
            <Box
                component="main"
                sx={{
                    width: mainWidthAvaliable,
                    margin: 0,
                    marginLeft: `${mainMarginLeft}px`,
                }}
            >
                <Container disableGutters maxWidth={false} sx={{ width: "100%" }}>
                    <Navbar></Navbar>

                    {/** Contato section */}
                    <ContatoSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Sobre Nós section  */}
                    <SobreNosSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Depoimentos section */}
                    <DepoimentosSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Nossas Soluções section */}
                    <NossasSolucoesSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Funcionalidades section */}
                    <FuncionalidadesSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Planos section */}
                    <PlanosSection gridDefaultProps={gridContainerDefaultProps} />
                </Container>
            </Box>
        </>
    );
};

function App() {
    return (
        <ThemeProvider theme={AggerTheme}>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                language="pt-BR"
            >
                <AppContentProvider>
                    <BrowserRouter>
                        <Main />
                        <Routes>
                            <Route path="/login" element={<LoginForm />}></Route>
                        </Routes>
                    </BrowserRouter>
                </AppContentProvider>
            </GoogleReCaptchaProvider>
        </ThemeProvider>
    );
}

export default App;
