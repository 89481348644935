import { FC, useState } from "react";
import { IconButton, List, ListItem, ListItemText, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CKEditor from "../../Sidebar/Ckeditor/CKEditor";
import { PlanoEditablePlan } from "../PlanoType";
import { getHtmlInnerText } from "../../../utils/Utility";

interface onPackageItemChangeParameters {
    editorData: string;
    planoIndex: number;
    type: string;
    subIndex: number;
}

interface PackagesProps {
    planoItem: PlanoEditablePlan;
    planoIndex: number;
    onPackageItemChange: (arg0: onPackageItemChangeParameters) => void;
}

const PackageItemEdition: FC<PackagesProps> = (props) => {
    const [packgeItemEditing, setPackgeItemEditing] = useState<number | undefined>();

    const planoItem = props.planoItem;
    const planoIndex = props.planoIndex;

    return (
        <List dense>
            {planoItem.packageItems.map((item, packageIndex) => {
                return (
                    <>
                        <ListItem
                            key={packageIndex}
                            sx={{ paddingRight: "96px" }}
                            secondaryAction={
                                <Stack direction="row" spacing={2}>
                                    <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() => {
                                            if (packgeItemEditing === packageIndex) {
                                                setPackgeItemEditing(undefined);
                                            } else {
                                                setPackgeItemEditing(packageIndex);
                                            }
                                        }}
                                    >
                                        {packgeItemEditing === packageIndex && <CheckIcon />}
                                        {packgeItemEditing !== packageIndex && <EditIcon />}
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            props.onPackageItemChange({
                                                editorData: "removePackageItem",
                                                planoIndex,
                                                type: "packageItems",
                                                subIndex: packageIndex,
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            }
                        >
                            <ListItemText>{getHtmlInnerText(item.text)}</ListItemText>
                        </ListItem>
                        {packgeItemEditing === packageIndex && (
                            <ListItem>
                                <CKEditor
                                    data={item.text}
                                    onChange={(editorData) => {
                                        props.onPackageItemChange({
                                            editorData,
                                            planoIndex,
                                            type: "packageItems",
                                            subIndex: packageIndex,
                                        });
                                    }}
                                />
                            </ListItem>
                        )}
                    </>
                );
            })}
            <ListItem>
                <ListItemText sx={{ textAlign: "center" }}>
                    <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={() => {
                            props.onPackageItemChange({
                                editorData: "Novo Item",
                                planoIndex,
                                type: "packageItems",
                                subIndex: planoItem.packageItems.length,
                            });
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </ListItemText>
            </ListItem>
        </List>
    );
};

export default PackageItemEdition;
