import React, { FC, SyntheticEvent, useCallback, useState } from "react";
import EditionAccordion from "../Sidebar/EditionAccordion";

import { useFuncionalidadeContentContext } from "./FuncionalidadeContentContext";
import { handleEditableContentChange, onPropChangeParameters } from "../../utils/Utility";
import EditionTabs from "../Sidebar/EditionTabs/EditionTabs";
import { FuncionalidadeContent, FuncionalidadeEditableContent } from "./FuncionalidadeType";

interface Props {}

const FuncionalidadeEdition: FC<Props> = (props) => {
    const [accordionExpanded, setAccordionExpanded] = useState<string | false>(false);

    const handleAccordionChange =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setAccordionExpanded(isExpanded ? panel : false);
        };

    const { funcionalidadeContent, updateFuncionalidadeContent } =
        useFuncionalidadeContentContext();

    const onPropChange = useCallback(
        (arg0: onPropChangeParameters<FuncionalidadeContent, FuncionalidadeEditableContent>) => {
            handleEditableContentChange({
                ...arg0,
                oldProps: funcionalidadeContent,
                update: updateFuncionalidadeContent,
            });
        },
        []
    );

    return (
        <>
            {funcionalidadeContent.texts.map((funcionalidadeItem, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={funcionalidadeItem.key}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                    >
                        <EditionTabs
                            tabs={funcionalidadeItem}
                            contentName="texts"
                            contentIndex={index}
                            onPropChange={onPropChange}
                        />
                    </EditionAccordion>
                );
            })}
            {funcionalidadeContent.funcionalidades.map((funcionalidade, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={funcionalidade.key}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                    >
                        <EditionTabs
                            tabs={{
                                title: funcionalidade.title,
                                description: funcionalidade.description,
                                popUpButton: funcionalidade.popUpButton,
                                knowMoreLink: funcionalidade.knowMoreLink,
                            }}
                            contentName="funcionalidades"
                            contentIndex={index}
                            onPropChange={onPropChange}
                        />
                    </EditionAccordion>
                );
            })}
        </>
    );
};

export default FuncionalidadeEdition;
