import React, { FC, useEffect, useState } from "react";
import { Box, Divider, Grid, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import AggerTheme from "../../../utils/AggerTheme";
import EditableTypography from "../../GenericEditableContent/EditableTypography";
import { FormModalEditableForm } from "../FormModalType";
import EditableFormModalDescription from "./EditableFormModalDescription";
import { backendHttp, getHtmlInnerText } from "../../../utils/Utility";
import Logo from "../../../images/logo-agger.png";
import EditableContrateFormForm from "./EditableFormModalForm";
import { SelectedPlanoInformation } from "../../Plano/PlanoType";

interface ipInformation {
    country_code: string;
    country_name: string;
    city: string;
    postal: string;
    latitude: number;
    longitude: number;
    IPv4: string;
    state: string;
}

interface Props {
    type: "hireAPI" | "contato";
    contrateFormEditableForm: FormModalEditableForm;
    selectedPlanoIndexInformation: SelectedPlanoInformation;
}

const EditableFormModal: FC<Props> = (props) => {
    const theme = useTheme();
    const upToDesktop = useMediaQuery(theme.breakpoints.up("desktop"));
    const upToLaptop = useMediaQuery(theme.breakpoints.up("laptop"));

    const [ipInformation, setIpInformation] = useState<ipInformation>();
    useEffect(() => {
        backendHttp.get("utility/getGeolocationData").then((res) => {
            console.log(res.data);
            setIpInformation(res.data as ipInformation);
        });
    }, []);

    return (
        <Grid
            container
            maxWidth="desktop"
            justifyContent={upToDesktop ? "space-between" : "space-evenly"}
            m="auto"
            columnGap={2}
            rowGap={upToLaptop ? 0 : 5}
            direction="row"
            wrap="wrap"
            alignItems="center"
            alignContent="flex-start"
            height="auto"
            minHeight="100%"
            py={8}
            px={upToLaptop ? 0 : 5}
            marginBottom={upToLaptop ? 0 : 4}
            overflow="hidden"
        >
            <Grid item xs={12} paddingBottom="50px">
                {upToLaptop && (
                    <Divider
                        sx={{
                            "::after, ::before": {
                                borderColor: AggerTheme.palette.primary.main,
                                borderWidth: "2px",
                            },
                        }}
                    >
                        <EditableTypography text={props.contrateFormEditableForm.title} />
                    </Divider>
                )}
                {!upToLaptop && (
                    <EditableTypography
                        text={props.contrateFormEditableForm.title}
                        textAlign="center"
                    />
                )}
            </Grid>
            <Grid item xs={upToLaptop ? 4 : 12}>
                <Stack spacing={2}>
                    <Box>
                        <img src={Logo} width={250} alt="Logo Agger" />
                    </Box>
                    {props.contrateFormEditableForm.descriptions.map((description, index) => {
                        return (
                            <EditableFormModalDescription key={index} description={description} />
                        );
                    })}
                    <Typography color="text.primary" variant="body1">
                        Seu IP: {ipInformation?.IPv4}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={upToLaptop ? 7 : 12}>
                <EditableContrateFormForm
                    type={props.type}
                    selectedPlanoIndexInformation={props.selectedPlanoIndexInformation}
                    submitButton={props.contrateFormEditableForm.submitButton}
                    ipInformation={{
                        IP: ipInformation?.IPv4 ? ipInformation?.IPv4 : "",
                        countryName: ipInformation?.country_name ? ipInformation?.country_name : "",
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default EditableFormModal;
