import React, { FC, SyntheticEvent, useCallback, useState } from "react";
import EditionAccordion from "../Sidebar/EditionAccordion";

import { useDepoimentoContentContext } from "./DepoimentoContext";
import { IconButton, Toolbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditionTabs from "../Sidebar/EditionTabs/EditionTabs";
import {
    handleEditableContentChange,
    onPropChangeParameters,
    createStyledAggerTheme,
} from "../../utils/Utility";
import { DepoimentosContent, DepoimentosEditableContent } from "./DepoimentosType";

const styled = createStyledAggerTheme();

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    transition: "all ease 0.3s",
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
})) as typeof IconButton;

interface Props {}

const DepoimentoEdition: FC<Props> = (props) => {
    const [accordionExpanded, setAccordionExpanded] = useState<string | false>(false);

    const handleAccordionChange =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setAccordionExpanded(isExpanded ? panel : false);
        };

    const { depoimentoContent, updateDepoimentoContent, addDepoimento, deleteDepoimento } =
        useDepoimentoContentContext();

    const deleteDepoimentoById = (e: any, index: number) => {
        e.stopPropagation();
        deleteDepoimento(index);
    };

    const onPropChange = useCallback(
        (arg0: onPropChangeParameters<DepoimentosContent, DepoimentosEditableContent>) => {
            handleEditableContentChange({
                ...arg0,
                oldProps: depoimentoContent,
                update: updateDepoimentoContent,
            });
        },
        []
    );

    return (
        <>
            {depoimentoContent.texts.map((depoimentoText, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={depoimentoText.key}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                    >
                        <EditionTabs
                            tabs={depoimentoText}
                            contentName="texts"
                            contentIndex={index}
                            onPropChange={onPropChange}
                        />
                    </EditionAccordion>
                );
            })}
            {depoimentoContent.depoimentos.map((depoimento, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={depoimento.key}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                        icons={[
                            <StyledIconButton>
                                <DeleteIcon
                                    onClick={(e) => {
                                        deleteDepoimentoById(e, index);
                                    }}
                                />
                            </StyledIconButton>,
                        ]}
                    >
                        <EditionTabs
                            tabs={{
                                name: depoimento.name,
                                profession: depoimento.profession,
                                deposition: depoimento.deposition,
                            }}
                            contentName="depoimentos"
                            contentIndex={index}
                            onPropChange={onPropChange}
                        />
                    </EditionAccordion>
                );
            })}
            <Toolbar sx={{ justifyContent: "center" }}>
                <StyledIconButton onClick={addDepoimento}>
                    <AddIcon />
                </StyledIconButton>
            </Toolbar>
        </>
    );
};

export default DepoimentoEdition;
