import React, { FC, SyntheticEvent, useState, useCallback } from "react";
import EditionAccordion from "../Sidebar/EditionAccordion";

import { useSobreNosContentContext } from "./SobreNosContentContext";
import { handleEditableContentChange, onPropChangeParameters } from "../../utils/Utility";
import EditionTabs from "../Sidebar/EditionTabs/EditionTabs";
import { SobreNosContent, SobreNosEditableContent } from "./SobreNosType";
import { EditableSubContentKeysType } from "../../types/aggerTypes";

interface Props {}

const SobreNosEdition: FC<Props> = (props) => {
    const [accordionExpanded, setAccordionExpanded] = useState<string | false>(false);

    const handleAccordionChange =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setAccordionExpanded(isExpanded ? panel : false);
        };

    const { sobreNosContent, updateSobreNosContent } = useSobreNosContentContext();

    const onPropChange = useCallback(
        (arg0: onPropChangeParameters<SobreNosContent, SobreNosEditableContent>) => {
            handleEditableContentChange({
                ...arg0,
                oldProps: sobreNosContent,
                update: updateSobreNosContent,
            });
        },
        []
    );

    return (
        <>
            {sobreNosContent.texts.map((sobreNosItem, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={sobreNosItem.key}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                    >
                        <EditionTabs
                            tabs={sobreNosItem}
                            contentName="texts"
                            contentIndex={index}
                            onPropChange={onPropChange}
                        />
                    </EditionAccordion>
                );
            })}
            {sobreNosContent.numbers.map((sobreNosNumero, index) => {
                return (
                    <EditionAccordion
                        key={index}
                        identification={sobreNosNumero.key}
                        identificationAlias={`Informativo ${index + 1}`}
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                    >
                        <EditionTabs
                            tabs={
                                {
                                    number: sobreNosNumero.number,
                                    text: sobreNosNumero.text,
                                } as EditableSubContentKeysType
                            }
                            contentName="numbers"
                            contentIndex={index}
                            onPropChange={onPropChange}
                        />
                    </EditionAccordion>
                );
            })}
        </>
    );
};

export default SobreNosEdition;
